const getSpacingClassesForBlok = (blok: {
  spacingBottom?: string | number;
  spacingTop?: string | number;
}) => {
  const spacingTopOptions = {
    0: 'pt-0',
    lg: 'pt-16',
    md: 'pt-8',
    sm: 'pt-4',
    xl: 'pt-24',
  };
  const spacingTopClass = spacingTopOptions[(blok.spacingTop ?? 'md') as keyof typeof spacingTopOptions];

  const spacingBottomOptions = {
    0: 'pb-0',
    lg: 'pb-16',
    md: 'pb-8',
    sm: 'pb-4',
    xl: 'pb-24',
  };
  const spacingBottomClass = spacingBottomOptions[(blok.spacingBottom ?? 'md') as keyof typeof spacingBottomOptions];

  return [
    spacingTopClass,
    spacingBottomClass,
  ];
};

const parseStoryblokDate = (date: string) => new Date(`${date}Z`);

export {
  getSpacingClassesForBlok, parseStoryblokDate,
};
